import { json, LoaderFunction } from "@remix-run/node";
import { DEFAULT_LANGUAGE } from "~/i18n";
import i18next from "~/i18next.server";
import { seoGenerator } from "~/old-app/utils/helpers/seo-generator";
import NotFound from "~/old-app/views/404";

interface LoaderData {
    url: URL;
    translations: {
        [key: string]: string;
    };
    lang: string;
}

export const loader: LoaderFunction = async ({ params, request }) => {
    const url = new URL(request.url);
    const lang = params?.lang || DEFAULT_LANGUAGE;
    const t = await i18next.getFixedT(lang);

    const title = t(`seo.notFoundTitle`);
    const description = t(`seo.notFoundDescription`);

    const translations = {
        title,
        description,
    };



    return json<LoaderData>({
        url,
        translations,
        lang,
    });
};

export const meta = ({ data }: { data: LoaderData }) => {
    const seo = seoGenerator({
        url: data?.url,
        title: data?.translations?.title,
        description: data?.translations?.description,
        lang: data?.lang,
    });

    return seo;
};

export default NotFound;
